// Imports => Constants
import { ENDPOINTS } from '@constants';

export class UsersAPI {
  constructor(Client, config) {
    this.Client = Client;
    this.config = config;
  }

  list = (params) => {
    return this.Client.get(ENDPOINTS.USERS.LIST, { params }).then(
      (response) => response.data
    );
  };

  get_by_id = (id) => {
    return this.Client.get(ENDPOINTS.USERS.GET_BY_ID(id)).then(
      (response) => response.data.data
    );
  };

  get_roles = (params) => {
    return this.Client.get(ENDPOINTS.USERS.ASSIGNABLE_ROLES, { params }).then(
      (response) => response.data.data
    );
  };

  verify_by_email = (data) => {
    return this.Client.post(ENDPOINTS.USERS.EXISTS, data).then(
      (response) => response.data
    );
  };

  add_user_to_company = (id, data) => {
    return this.Client.put(ENDPOINTS.USERS.ADD_TO_COMPANY(id), data).then(
      (response) => response.data
    );
  };

  store = (data) => {
    return this.Client.post(ENDPOINTS.USERS.STORE, data).then(
      (response) => response.data.data
    );
  };

  update = (id, data) => {
    return this.Client.put(ENDPOINTS.USERS.UPDATE(id), data).then(
      (response) => response.data.data
    );
  };

  patch = (id, data) => {
    return this.Client.put(ENDPOINTS.USERS.UPDATE(id), data).then(
      (response) => response.data.data
    );
  };

  delete = (data) => {
    return this.Client.delete(ENDPOINTS.USERS.DELETE(data)).then(
      (response) => response
    );
  };
}

export default UsersAPI;
