// Imports => Constants
import { ENDPOINTS } from '@constants';

export class AuthAPI {
	constructor(Client, NoAuthClient, config) {
		this.Client = Client;
		this.NoAuthClient = NoAuthClient;
		this.config = config;
	}

	login = (data) => {
		return this.NoAuthClient.post(ENDPOINTS.OAUTH.LOGIN, {
			...data,
			grant_type: process.env.GRANT_TYPE,
			client_secret: process.env.CLIENT_SECRET,
			client_id: process.env.CLIENT_ID,
		}).then((response) => response.data);
	};

	sso_login = (data) => {
		return this.NoAuthClient.post(ENDPOINTS.OAUTH.LOGIN, {
			...data,
			grant_type: process.env.SSO_GRANT_TYPE,
			client_secret: process.env.CLIENT_SECRET,
			client_id: process.env.CLIENT_ID,
		}).then((response) => response.data);
	};

	forgot_password = (data) => {
		return this.NoAuthClient.post(ENDPOINTS.OAUTH.FORGOT_PASSWORD, data).then(
			(response) => response.data
		);
	};

	reset_password = (data) => {
		return this.NoAuthClient.post(ENDPOINTS.OAUTH.RESET_PASSWORD, data).then(
			(response) => response.data
		);
	};

	impersonate = (id) => {
		return this.Client.get(ENDPOINTS.OAUTH.IMPERSONATE(id)).then(
			(response) => response.data
		);
	};

	stop_impersonating = (id) => {
		return this.Client.delete(ENDPOINTS.OAUTH.STOP_IMPERSONATE(id)).then(
			(response) => response.data
		);
	};
}

export default AuthAPI;
